<template>
  <div class="relative min-h-full min-w-[320px]">
    <slot />
  </div>
</template>

<script setup lang="ts">
const route = useRoute();
const config = useRuntimeConfig();
//set SEO parameters
useSeoMeta({
  title: () => route.meta.title as string,
  ogTitle: () => route.meta.title as string,
  description: () => route.meta.description as string,
  ogDescription: () => route.meta.description as string,
  ogImage: () =>
    route.meta.seoImg
      ? `${config.public.url}/images/meta/${route.meta.seoImg}.jpg`
      : undefined,
  twitterCard: "summary",
});
</script>

<style scoped></style>
